<template>
  <div>
   
    <div class="pb-4 text-right">
      <b-button size="md" variant="primary" :to="'/template/add'" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-plus"></i></b-button>
      </div>
    <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th style="min-width: 50px; width: 1px;">เลือก</th>
                  <th>ชื่อ Templeate</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in items" :key="`template_list_${index}`"
                    :id="`checkbox_template_index${index}`">
                  <td class="text-center checkbox_style" >
                    <b-form-checkbox :name="`checkbox_group${data.id}`" v-model="data.selected"/>
                  </td>
                  <td>{{data.name}}</td>
                  <td class="text-center">{{data.created | dateFormat('YYYY.MM.DD')}}</td>
                  <td class="text-center">{{data.updated}}</td>
                  <td class="text-center" >
                    <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                      <i class="fas fa-pen" @click="onEditHandler(data.id)" title="แก้ไข"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div  class="col-lg-1 col-md-2 col-3"
              style="margin-top: 30px;">
          <select class="form-control mb-2"
                  id="pageLimit"
                  style="width: 80px;"
                  v-model="paging.limit" v-on:change="changeLimit()">
            <option v-for="(data) in paging.pages"
                    :key="data"
                    :value="data">
              {{data}}
            </option>
          </select>
        </div>
        
      </div>
    </div>
   
  </div>
</template>
<script>
  import config from '../../../util/config';
  import $ from 'jquery';
  export default {
    name: 'template-view-page-template',
    data() {
      return {
        permission:{
          appSlug: 'template',
          actionData: {}
        },
        criteria: {
          search: "",
          orderBy: "",
          ascDesc: ""
        },
        items: [],
        paging: {
          page: 1,
          limit: config.pagination.limit,
          pages: config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0,
          pageSizes: []
        }
      };
    },
    props: [],
    methods: {
      async getListItems () {
        const result = await this.HttpServices.getData(`/ab_template/list`);
        if(result&&result.status.code=="200"){
          
          this.items = result.data;
        }else{
          this.items = [];
        }
      },onEditHandler(id){
          this.SessionStorageUtils.setSession("page-template", id);
          this.$router.push(`/template/edit/${id}`);
      },
    },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    await this.getListItems();
    this.SessionStorageUtils.deleteSession("page-template");
  }
  };
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>
