<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-store"></i> ต้นแบบเอกสาร </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-template />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import pageTemplate from "./view/page.template.vue";
  export default {
    name: 'template-index',
    components: {
      pageTemplate
    }
  };
</script>
<style>

</style>
